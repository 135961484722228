import axios from "axios";
import store from "@/store";

let ip = "https://ejpartners.mx/api_ejp/";
//let ip = "http://localhost/api_ejp/";

function api() {
  return axios.create({
    baseURL: ip,
    headers: {
      Authorization: `Bearer ${store.state.tokenState}`,
    },
  });
}

export default {
  enviarCorreo(datos) {
    return api().post("correo", datos);
  },
  agregarInscripcion(datos) {
    return api().post("guardarInscripcion", datos);
  },
  iniciarSesion(datos) {
    return api().put("iniciarSesion", datos);
  },

  lista() {
    return api().get("admin/lista");
  },

  fotoSuscriptor(id) {
    return api().get(`admin/fotoSuscriptor/${id}`);
  },
};
